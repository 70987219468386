import styled, {css} from 'styled-components';
import { Modal } from 'react-bootstrap';
import { DropdownButton, DropdownItem } from "react-bootstrap";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .first-section{
    width: 32%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  .second-section{
    width: 68%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 25px;
    height: 100%;
  }
`;

const Heading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 25px 0px 15px 0px;
  .heading{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color: #0D4270
  }
  .edit-button{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color: #FD7175;
    cursor: pointer;
  }
  .dropdown-container{
    display: flex;
  }
`;

const TodayTipCard = styled.div`
  width: 100%;
  background: ${({background}) => background ? background : 'rgba(255, 191, 193, .2)' };
  margin: ${({margin}) => margin ? "0 0 15px 0" : '0' };
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  .tip{
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color: ${({color}) => color ? color : '#FD7175' };
    padding: 15px 15px 30px 15px;
  }
  .author{
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color: ${({color}) => color ? color : '#FD7175' };
    padding: 0px 15px 25px 15px;
  }
  .wrapper{
    width: 80%;
    display: block;
  .dailytip{
    width: 100%;
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color: ${({color}) => color ? color : '#FD7175' };
    padding: 15px 15px 30px 15px;
    text-align: justify;
  }
  .author{
    width: 100%;
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color: ${({color}) => color ? color : '#FD7175' };
    padding: 0px 15px 25px 15px;
  }
}
  .editSection{
    width: 20%;
    margin: 15px auto; 
    .edit{
      width: 40px;
      height: 40px;
      margin: auto 15px 20px auto;
      >img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .delete{
      width: 40px;
      height: 40px;
      margin: auto 15px auto auto;
      >img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
`;

const AddTipButton = styled.div`
  width: 100%;
  height: 50px;
  background: rgba(253, 113, 117, 1);
  margin-top: 25px;
  display: flex;
  cursor: pointer;
  border-radius: 6px;
  .image{
    display: flex;
    margin: 5px;
    width: 40px;
    height: 40px;
    background: pink;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  .text{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color:#FFF
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
`;

const TipCardWrapper = styled.div`
  width: 100%;
  display: block;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    transform: ${({transform}) => transform ? 'translate(0, 15vh) translate(0, -15%) !important' : 'translate(0, 35vh) translate(0, -50%) !important'};
    margin: ${({change}) => change ? '150px auto' : '0 auto'};
    .modal-content{
      border:none;
    }
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
    width: ${({width}) => width ? width : '490px'};
    height: ${({change}) => change ? '573px' : '473px'};
    @media (max-width: 500px){
      width: 95%;
      height: auto;
    }
  }

  .modal-body{
    padding: 25px 0 25px 0;
  }
  
  .modal-container {
   width: 100%;
   display: block;
   border: none;
   > div: nth-child(1) {
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin: 15px 15px 0 0;
    }
   }
   > div: nth-child(2) {
    width: 100%;
    display:flex;
    justify-content: center;
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 24px;
    color:#0D4270;
    text-decoration: underline;
    margin-top: 35px;
   }
  }
`;

const CalendarHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
  border-radius: 6px 6px 0 0;
  .heading {
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color:#FFF;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    margin-left: 15px;
  }
  .cancel {
    display: flex;
    margin: auto;
    margin-right: 15px;
    >img{
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
  .text{
    width: 100%;
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color:#FFF;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    margin-left: 15px;
    justify-content: center;
  }
`
const CalendarWrapper = styled.div`
  padding: ${({padding}) => padding ? padding : '0 25px'};
`
const FieldTitle = styled.span`
  font-size: 16px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 20px;
  color: ${({color}) => color ? color :'#20495E'};
  font-family: rubik-medium;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  display: flex;
  .required-field{
    color: rgba(253,113,117,1)
  }
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;



const CustomTextArea = styled.textarea`
  font-size: 16px;
  line-height: 20px;
  padding: ${({padding}) => padding ? '0 15px' : '15px'};
  border-radius: 4px;
  border: 1px solid rgba(156, 156, 156, 1);
  width: 100%;
  margin-top: 16px;
  resize: none;
  color: #0D4270;
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  height: ${({height}) => height ? height : '165px'};
  background: rgba(156, 156, 156, 0.05);
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: #9c9c9c;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #9c9c9c;
  }

  ::-ms-input-placeholder {
    color: #9c9c9c;
  }
`;


const TitleContainer = styled.div`
  width: 100%;
  display: block;
  height:100%;
  margin-bottom: 25px;
  .dropdown{
    width: 100%;
    >button{
      width:100%;
      text-align: left;
      padding:0px;
    }
  }
  >div{
  .dropdown-menu {
    width: 100%;
    transform: translate(0px, 37px) !important;
    >li{
      >a{
        width: 100%;
        display: flex;
        &:active, &:hover, &:focus, &:active:focus {
        outline: none;
        background: rgba(105,194,255,0.1);
        color: #0D4270;
      }
    }
    }
  }
  .dropdown-menu>.active>a{
    background: rgba(105,194,255,0.1);
    color: #0D4270;
  }
}
`;

const StyledInput = styled.input`
font-size: 16px;
line-height: 20px;
padding: ${({padding}) => padding ? '0 15px' : '15px'};
border-radius: 4px;
border: 1px solid rgba(156, 156, 156, 1);
width: 100%;
margin-top: 16px;
resize: none;
color: #0D4270;
cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
height: ${({height}) => height ? height : '50px'};
background: rgba(156, 156, 156, 0.05);
&:focus, &:active {
  outline: none;
}

::placeholder {
  color: #9c9c9c;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #9c9c9c;
}

::-ms-input-placeholder {
  color: #9c9c9c;
}
`;

const UploadButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(253, 113, 117, 1);
  height: 50px;
  cursor: pointer;
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color: #FFF;
  border-radius: 6px;
  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Button = styled.div`
  width: calc((100% - 25px)/2);
  display: flex;
  height: 50px;
  border: ${({border}) => border ? '1px solid rgba(156, 156, 156, 1)' : '0'};
  background: ${({background}) => background ? background : '#FD7175'};
  color: ${({color}) => color ? color : '#fff'};
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
`;

const PopupHeading = styled.div`
  width: 100%;
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color:#0D4270;
  margin-bottom: 35px;
  text-align: center;
`;


const PaginationCard = styled.div`
    margin-top: 35px;
    margin-bottom: 35px;
    width: 100%;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
  
`;

const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #9C9C9C;
margin: 0px 10px;
align-items: center;
display: flex;
`;

const CustomDropDown = styled(DropdownButton)`
  width: 100%;
  display: ${({inputdisplay}) => inputdisplay ? inputdisplay : 'inline-block'};
  color: ${({newWidth}) => newWidth ? 'white' : 'white'};
  background-color: ${({newWidth}) => newWidth ? '#0D4270' : '#ffffff'};
  align-items: center;
  padding: 0px 10px;
  border: 1px solid #cdcbcb;
  text-align: left;
  border: none;
  padding: ${({inputpadding,newWidth}) => inputpadding ? inputpadding : newWidth ? '9px 16px' : '9px 29px'};
  border-radius: 3px;
  color: #9c9c9c;
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Rubik';
    float: left;
    max-width:118px;
    white-space:nowrap;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
    text-transform: capitalize;
  }
  > ul {
    max-width: 300px !important;
  }
  font-size: 14px;
  letter-spacing: 0px;
  color: #282C37;
  font-family: "Rubik-Medium";
  color: #9c9c9c;
  .caret {
    margin-right: 8px;
    float: right;
    margin-top: 5px;
    display: flex;
    align-self: center;
    color: rgba(105, 194, 255, 1);
    border-width: 10px;
  }
  > li > a {
    text-overflow: ellipses;
    white-space: normal;
    overflow: hidden;
  }
  > span {
    color: #a3a3a3;
    float: right;
    border-top: 6px dashed;
    margin-top: 7px;
  }
  &:active{
    color: #0D4270;
  }
`;

const YearDropDown = styled(CustomDropDown)`
  float: right;
  align-items: center;
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 140px;
  height: 40px;
  background-color: rgba(105, 194, 255, 0.1);
  border-radius: 6px;
  box-shadow: none;
  margin-top: ${({role}) => (role === 'ADMIN') ? '0px' : '0px'};
  padding: 10px;
  >button{
    background:none;
    padding:0px;
    width:100%;
    border:none;
  }
  >button:active{
    background:none;
  }
  .btn-check:checked+.btn, .btn.active, .btn.hover, .btn.show, .btn:first-child:active, .btn:first-child:hover, :not(.btn-check)+.btn:active{
    background:none;
  }
  .dropdown-toggle::after {
    display: flex;
    margin: auto;
    width: 10px;
    margin-right: 0px;
    margin-top: 5px;
    color: rgb(105, 194, 255);
  }
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }
`;

const ActivityDropdown = styled(DropdownButton)`
font-size: 16px;
line-height: 20px;
padding: 0 15px;
border-radius: 4px;
border: 1px solid rgba(156,156,156,1);
width: 100%;
margin-top: 16px;
resize: none;
color: #0D4270;
height: 50px;
background: rgba(156,156,156,0.05);

  > span {
    float: right;
    top: 20px;
    position: absolute;
    right: 10px;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    color: #9c9c9c;
    border: 1px solid #9C9C9C;
  }
`;

const CustomMenuItem = styled(DropdownItem)`
  width: 100%;
  text-transform: capitalize;
  > a {
    &:active, &:hover, &:focus {
      outline: none;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 50px;
  border: 1px solid rgba(156,156,156,1);
  margin-top: 15px;
  background: rgba(156,156,156,0.05);
  border-radius: 6px;
  &:active, &:hover, &:focus {
    outline: none;
    background-color: none;
    background: rgba(156,156,156,0.05);
  }
  label {
    width: 100%
  }
  .btn-group {
    width: 100%;
    height: 50px;
    .title {
    }
  }

  .dropdown-menu {
    width: 100%;
    height: auto;
    max-height: 146px;
    overflow-x: hidden;
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    ${'' /* text-align: center; */}
    ::-webkit-scrollbar {
     width: 5px;
     height: 5px;
   }
    ::-webkit-scrollbar-thumb {
     background: #99999C;
     cursor: pointer;
     border-radius: 2.5px;
   } 

    ::-webkit-scrollbar-track {
     background: #fff;
     border-radius: 2.5px;
   }
    > li {
        > a:  {
          padding: 5px 15px;
        }
      }
    > ul {
     width: 100%;
    }
    .right_cross_icon {
      width: 10px;
      height: 20px;
    }
  }
  .dropdown-toggle.btn-default:hover, .dropdown open btn-group  {
    background:rgba(156,156,156,0.05);
    border-color: #ccc;
    box-shadow: none;
    color: none;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    background:rgba(156,156,156,0.05);
  }
  .btn-default.focus, .btn-default:focus{
    background:rgba(156,156,156,0.05);
  }
`;

const DropDown = styled(CustomDropDown)`
  width: ${({width}) => width ? width : '100%'};
  box-shadow: none;
  padding: ${({padding}) => padding ? padding : '9px 10px'};
  color: #9C9C9C;
  border: none;
  height: 50px;
  background: none;
  font-size: 16px;
  line-height: 20px;
  font-family: rubik;
  height: 48px;
  color: ${({color}) => color ? color : '#9c9c9c'};
  &:active{
    color: #0D4270;
  }
  display:flex;
  align-items:center;
  .title {
    overflow: auto;
    text-overflow: ellipsis;
    font-family: "Rubik-Medium";
    float: left;
    max-width:250px;
    white-space:nowrap;
  }
  .caret{
    width: 0px;
    display: none;
  }
  .dropdown-toggle.btn-default:hover, .dropdown open btn-group  {
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
  }

  button{
    background:transparent !important;
    border:none;
    color:#005C87 !important;
    width:100%;
    display:flex;
  }

  button:hover{
    color:#005C87;
  }

  button {
    .show{color:#005C87 !important;
    }

  }

  button:focus{
    background-color: transparent !important;
  }
  button:focus-visible{
    background-color: transparent !important;
  }
  button:active{
    background-color: transparent !important;
    color:#005C87 !important;
  }

  .dropdown-toggle::before{
    visibility:hidden;
  }

  .dropdown-toggle::after{
    visibility:hidden;
  }
`;

const RightButton = styled.div`
width: 100%;
display: flex;
justify-content : flex-end;
height: 0px;
position: relative;
bottom 35px;
z-index: 5;
>img{
  width: 20px;
  height: 20px;
  margin-right: 15px;
  z-index: 7;
}
`;

const TotalTipwrapper = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
margin-top: 25px;
`;

const TotalTipHeading = styled.div`
width: 100%;
display: flex;
height: 50px
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 24px;
color:#FFFFFF;
background: #0D4270;
border-radius: 6px 6px 0 0;
align-items: center;
padding-left: 15px;
`;

const TotalCategoryWrapper = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 10px 15px 15px 15px;
border-radius: 0 0 6px 6px;
`;

const TotalCategoryCard = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
.categoryCardData{
  width: 100%;
  display: flex;
  margin: 5px 0 15px 0px;
.categoryImage{
  width: 50px;
  height: 50px;
  display: flex;
  margin: auto 15px auto 0px;
  >img{
    width: 100%;
    height: 100%;
  }
}
.categoryName{
  font-family: Rubik;
  font-size: 16px;
  line-height: 20px;
  color: #0D4270;
  display: flex;
  margin-left : 0px;
  align-items: center;
}
.tipCountCard{
  width: 72px;
  height: 38px;
  display: flex;
  margin: auto;
  margin-right: 0px;
  align-items: center;
  justify-content: center;
  background: ${({background}) => background ? background : 'white'};
  font-family: 'Rubik-Medium';
  font-size: 16px;
  line-height: 20px;
  color: ${({color}) => color ? color : '#0D4270'};
  border-radius: 6px;
}
}

.border{
  width: 100%;
  height: 1px;
  background: rgba(156, 156, 156, 0.3);
  margin-bottom 15px;
}
.totalCount{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  .text{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color:#0D4270;
  }
  .count{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color:#0D4270;
  }
}
`;

const TotalCategoryCardData = styled.div`
  width: 100%;
  display: flex;
  margin: 5px 0 15px 0px;
.categoryImage{
  width: 50px;
  height: 50px;
  display: flex;
  margin: auto 15px auto 0px;
  >img{
    width: 100%;
    height: 100%;
  }
}
.categoryName{
  font-family: Rubik;
  font-size: 16px;
  line-height: 20px;
  color: #0D4270;
  display: flex;
  margin-left : 0px;
  align-items: center;
}
.tipCountCard{
  width: 72px;
  height: 38px;
  display: flex;
  margin: auto;
  margin-right: 0px;
  align-items: center;
  justify-content: center;
  background: ${({background}) => background ? background : 'white'};
  font-family: 'Rubik-Medium';
  font-size: 16px;
  line-height: 20px;
  color: ${({color}) => color ? color : '#0D4270'};
  border-radius: 6px;
}
`;

const TopCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 25px;
`;

const TopCard = styled.div`
  display: flex;
  width: calc((100% - 75px) / 4);
  margin-right: 25px;
  background: white;
  flex-wrap: wrap;
  height: 140px;
  border-radius: 6px;
  .wrapper{
    width: 100%;
    display: flex;
  .image{
    height: 50px;
    width: 50px;
    margin: 15px 15px 0px 15px;
    >img{
      width: 100%;
      height: 100%;
    }
  }
.titleWrapper{
  display: block;
  margin: auto;
  margin-left: 0px;
  .title{
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 20px;
    color:#0D4270;
    margin: auto;
  }
}

}
  .points{
    font-family: 'Rubik-Medium';
    font-size: 36px;
    line-height: 24px;
    color: ${({color}) => color ? color : '#0D4270'};
    margin: auto 0 15px 15px;
  }
`;

export{ Container, Heading, TodayTipCard, AddTipButton, TipCardWrapper, StyledModal, CalendarHeader, CalendarWrapper, TitleContainer, FieldTitle, CustomTextArea, StyledInput, UploadButton, ButtonWrapper, Button, PopupHeading, PaginationCard, NewLabel, YearDropDown, CustomDropDown, ActivityDropdown, CustomMenuItem, RightButton, DropDown, ContentWrapper, TotalTipwrapper, TotalTipHeading, TotalCategoryWrapper, TotalCategoryCard, TotalCategoryCardData, TopCardsWrapper, TopCard};
