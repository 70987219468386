import styled from "styled-components";
import { Modal } from "react-bootstrap";

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 713px;
    border-radius: 6px;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),#ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    .modal-content {
      padding: 25px;
      background: rgba(248, 168, 135, 0.1);
    }
    .close {
      margin-top: -30px;
    }
    @media (max-height: 1200px) {
      width: 55%;
    }
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 766px) {
      width: 95%;
    }
    @media (min-width: 576px){
      max-width:none;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  padding: 25px 0;
  border-bottom: none;
  text-align: center;
  display:block !important;
  > span {
    font-size: 18px;
    font-family: "Rubik-Medium";
    color: #0d4270;
    text-decoration: underline;
    text-transform: capitalize;
  }
  
`;
const StyledBody = styled(Modal.Body)`
  height: 100%;
  width: 100%;
`;
const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 6px;
  @media (max-width: 400px) {
    padding: 10px;
  }

  > span {
    text-align: center;
    font-size: 16px;
    color: #fd7175;
    font-family: "Rubik-Regular";
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 60px;
`;
const TipsButton = styled.button`
  width: 65% !important;
  height: 50px !important;
  outline: none !important; 
  border: none !important;
  background-color: #fd7175 !important;
  border-radius: 6px !important;
  > span {
    color: #ffffff !important;
    font-family: "Rubik-Medium" !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
  }
`;
export {
  StyledModal,
  StyledHeader,
  StyledBody,
  ContentContainer,
  ButtonContainer,
  TipsButton,
};
