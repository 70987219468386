import styled from 'styled-components';
import {Modal} from "react-bootstrap";
import { SaveButton } from '../InputGoals/styles';
import { CheckLogo } from '../Content/styles';

const BoldFont = 'Open-Sans-Bold';
const MediumFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 550px;
    border-radius: 6px;
    background: ${({bgcolor, deviceBgColor}) => deviceBgColor ? 'linear-gradient(180deg, #FCE7D3 0%, rgba(255, 255, 255, 0)100%), #FFFFFF' : bgcolor ? 'linear-gradient(180deg, #E2FCD3 0%, rgba(255, 255, 255, 0)100%), #FFFFFF' : 'linear-gradient(180deg, #BAD5EA 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF'};
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    .modal-content {
      padding: 25px;
      background-color: ${({bgcolor}) => (bgcolor || !bgcolor) ? 'transparent' : '#fff'};
    }
    .close {
      margin-top: -30px;
    }
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 767px) {
      width: 90%;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  padding: 25px 0;
  border-bottom: ${({borderbottom})=> borderbottom && 'none'};
  > img {
    float: left;
    width: 30px;
    margin-right: 10px;
  }

  > span {
    font-size: 17px;
    letter-spacing: 0px;
    color: ${({color}) => color ? color : 'rgb(51,51,51)'};
    font-weight: ${({color}) => color && '500'};
    font-family: ${MediumFont};
    line-height: 32px;
    text-transform: ${({ uppercase }) => uppercase && 'uppercase'}
  }
`;

const InfoIcon = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: #ef6727;
  text-align: center;
  display: inline-block;
  margin-right: 9px;
  font-family: ${BoldFont};
`;

const AssessmentPanel = styled.div`
  background-color: #ef6727;
  width: 100%;
  height: auto;
  font-family: ${BoldFont};
  display: block;
  color: white;
`;

const TipContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  padding: ${({padding}) => padding ? '14px' : '10px 0px 10px 10px'};
  cursor: pointer;
  > span {
    font-size: 16px;
    letter-spacing: 0px;
    color: rgb(255,255,255);
    @media (max-width: 400px) {
      font-size: 16px;
    }
  }
  > div:last-child{
    width: calc(100% - 34px);
    font-size: ${({padding}) => padding ? '14px' : '11px'};
    text-align: center;
    letter-spacing: 1px;
    color: #FFFFFF;
    font-weight: 600;
    font-family: ${MediumFont};
    text-transform: uppercase;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  @media (max-width: 400px) {
    padding: 10px;
  }
  
  > span {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 23px;
    font-family: ${RegularFont};
    color: #484856;
  }
`;

const TipButton = styled(SaveButton)`
  padding: 0;
  height: 45px;
  width: 243px;
  background-color: #1E76AB;
  @media (max-width: 400px) {
    width: auto;
    padding: 10px 20px;
  }

  >span {
    padding-left: 15px;
    font-family: ${MediumFont};
  }
`;

const CheckedLogo = styled(CheckLogo)`
  border: 2px solid #1493ba;
  font-size: 12px;
  background-color: #1493ba;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 70px;
`;
const HraIcon = styled.div`
  height:  ${({width}) => width ? '29px' : '25px'};
  width: ${({width}) => width ? '29px' : '25px'};
  float: left;
  >img{
   width: 25px;
   height: 25px;
  }
`;
const HraAssessmentPanel = styled.div`
  background: ${({bgcolor}) => bgcolor ? 'linear-gradient(180deg, #D94793 0%, #A7124E 100%)' : 'linear-gradient(180deg, #37ED7D 0%, #129A8E 100%)'};
  box-shadow: ${({boxShadow})=> boxShadow? 'none' : '0px 5px 10px rgba(0, 0, 0, 0.08)'};
  border-radius: ${({borderRadius}) => borderRadius ? '6px' : '0px'};
  width: 100%;
  height: auto;
  display: block;
`;
export { StyledModal, StyledHeader, InfoIcon, AssessmentPanel, ContentContainer, TipButton, CheckedLogo, TipContent, ButtonContainer, HraIcon, HraAssessmentPanel};
