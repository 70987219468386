import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Interweave } from 'interweave';
import { withTranslation } from 'react-i18next';
import { StyledModal, ContentContainer, TipsButton, ButtonContainer, StyledHeader, StyledBody } from './styles';

class DailyTip extends Component {
  render() {
    const { showModal, dailyTip, onClose, showBiometricPopUp, t } = this.props;

    return (
      <StyledModal show={showModal} bgcolor={showBiometricPopUp ? 0 : 1}>
        <StyledHeader>
          <span>{t("Your Daily Tip")}</span>
        </StyledHeader>
        <StyledBody>
          <ContentContainer>
            <span><Interweave content={dailyTip.daily_tip} /></span>
          </ContentContainer>
          <ButtonContainer>
            <TipsButton onClick={() => onClose()}>
              <span>{t("Thanks for the tip")}</span>
            </TipsButton>
          </ButtonContainer>
        </StyledBody>
      </StyledModal>
    );
  }
}

DailyTip.propTypes = {
  showModal: PropTypes.bool,
  status: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  dailyTip: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  thanksForTheTipAPI: PropTypes.func,
  wellnessAssessment: PropTypes.object,
  showBiometricPopUp: PropTypes.bool,
  t: PropTypes.func
};

export default withTranslation()(DailyTip);
